import {Box, FormControl, Option, Select} from '@mui/joy';
import PropTypes from 'prop-types';
import React, {useMemo} from 'react';
import {Controller} from 'react-hook-form';
import {useTranslation} from 'react-i18next';
import {requiredFieldProps} from './helper';
import {FormErrorText} from '../../../../../components/joy-ui/forms/FormErrorText';
import {FormFieldLabel} from '../../../../../components/joy-ui/forms/FormFieldLabel';
import {TechSelectedOffer} from '../../../../../v1/app/company/company.consts';
import {UserSelect} from '../../user-select/components/UserSelect';

const AdditionalTopTechFields = ({control, isLoading, errors, isSubmitted}) => {
    const {t} = useTranslation('createUser');

    const selectedOfferOptions = useMemo(() => {
        return Object.values(TechSelectedOffer).map(selectedOffer => (
            <Option value={selectedOffer} key={selectedOffer}>
                {t(`selectedOffer.${selectedOffer}`, {ns: 'companies'})}
            </Option>
        ));
    }, [t]);

    return (
        <>
            <Box
                sx={{
                    gridColumn: {
                        desktop: '1',
                        mobile: '1',
                    },
                }}
            >
                <UserSelect
                    data-cy="coach"
                    label={t('coach')}
                    name="coach"
                    control={control}
                    isDisabled={isLoading}
                    hasError={!!errors.coach}
                    helperText={errors.coach?.message}
                    tabIndex={5}
                    isV3
                />
            </Box>
            <Box
                component="div"
                sx={{
                    gridColumn: {
                        desktop: '2',
                        mobile: '1',
                    },
                }}
            >
                <Controller
                    control={control}
                    name="selectedOffer"
                    render={({field}) => (
                        <FormControl error={!!errors.selectedOffer && isSubmitted}>
                            <FormFieldLabel label={t('tax')} />
                            <Select
                                data-cy="selectedOffer"
                                {...field}
                                color={errors.selectedOffer && isSubmitted ? 'danger' : 'neutral'}
                                disabled={isLoading}
                                error={!!errors.selectedOffer && isSubmitted}
                                placeholder={t('tax')}
                                tabIndex={7}
                                onChange={(_, value) => {
                                    field.onChange(value);
                                }}
                                {...requiredFieldProps}
                            >
                                {selectedOfferOptions}
                            </Select>
                            <FormErrorText errorMessage={isSubmitted ? errors.selectedOffer?.message : undefined} />
                        </FormControl>
                    )}
                />
            </Box>
        </>
    );
};

AdditionalTopTechFields.propTypes = {
    control: PropTypes.object.isRequired,
    isLoading: PropTypes.bool,
    errors: PropTypes.object,
    isSubmitted: PropTypes.bool.isRequired,
};

AdditionalTopTechFields.defaultProps = {
    isLoading: false,
    errors: null,
};

export default AdditionalTopTechFields;
