import RestartAltIcon from '@mui/icons-material/RestartAlt';
import {Button, CircularProgress, Typography} from '@mui/material';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import FlowStepWrapper from './FlowStepWrapper';
import CategorisationSection
    from '../../../../../bank/modules/account-balance/components/categorisation/components/header/CategorisationSection';
import {LoadingSelectors, LoadingTypes} from '../../../../../loading';
import {AccountingActions} from '../../../accounting/store/accounting.action';
import {DEPOSIT_OF_ACCOUNTS_STATUS} from '../../utils/constants';

const FlowStepFormalityReset = ({status, companyId, annualAccountId}) => {
    const {t} = useTranslation('companies');
    const dispatch = useDispatch();

    const resettingAnnualAccountInProgress = useSelector(
        LoadingSelectors.createLoadingSelectorByType(LoadingTypes.RESETTING_ANNUAL_ACCOUNT_SUBMISSION),
    );

    const handleInpiReset = () => {
        dispatch(AccountingActions.resetAnnualAccountSubmission({
            companyId,
            annualAccountId,
        }));
    };

    return status !== DEPOSIT_OF_ACCOUNTS_STATUS.REJECTED_BY_INPI
        ? null
        : (
            <FlowStepWrapper>
                <CategorisationSection
                    isActive={true}
                    isFocused={true}
                    sx={{width: '100%', maxWidth: '600px'}}
                >
                    <Box sx={{display: 'flex', alignItems: 'center', width: '100%'}}>
                        <Typography sx={{fontSize: '16px'}}>
                            {t('formalities.depositOfAccounts.flowSteps.inpiReset.text')}
                        </Typography>
                        <Button
                            sx={{py: 1, px: 2, minWidth: 173}}
                            variant="contained"
                            startIcon={resettingAnnualAccountInProgress
                                ? <CircularProgress size={24} />
                                : <RestartAltIcon />
                            }
                            onClick={() => handleInpiReset()}
                            disabled={!!resettingAnnualAccountInProgress}
                        >
                            {t('formalities.depositOfAccounts.flowSteps.inpiReset.resetButton')}
                        </Button>
                    </Box>
                </CategorisationSection>

            </FlowStepWrapper>
        );
};

FlowStepFormalityReset.propTypes = {
    status: PropTypes.string.isRequired,
    companyId: PropTypes.string.isRequired,
    annualAccountId: PropTypes.string.isRequired,
};

export default FlowStepFormalityReset;
