export const formatNumberWithSpaces = number => new Intl.NumberFormat('fr-FR', {maximumFractionDigits: 0}).format(number);

// Normalise data to accommodate the progress component that requires values between 0 and 100 (%)
// If value is greater than or to equal goal return 100(%), otherwise calculate %
export const normalizeValueForProgress = (value, goal) => (value >= goal ? 100 : Math.round((value / goal) * 100));

// eslint-disable-next-line
export const normalizePercent = (value, goal) => Number(((value / goal) * 100).toFixed(1)) % Number.POSITIVE_INFINITY || 0;

export const formatCompactNumber = number => {
    const formatter = Intl.NumberFormat('en-US', {
        notation: 'compact',
        compactDisplay: 'short',
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
    });
    return `${formatter.format(number)} €`;
};

export const formatCompactNumberWithOptions = ({decimalPlaces = 1}) => number => {
    const formatter = Intl.NumberFormat('en-US', {
        notation: 'compact',
        compactDisplay: 'short',
        minimumFractionDigits: 0,
        maximumFractionDigits: decimalPlaces,
    });
    return `${formatter.format(number)} €`;
};

export const formatOrdinalNumber = (n, lang = 'fr') => {
    if (n === 0) return 0;
    if (lang === 'fr') {
        return n === 1 ? `${n}er` : `${n}e`;
    }
    const suffixes = ['th', 'st', 'nd', 'rd'];
    const v = n % 100;
    return n + (suffixes[(v - 20) % 10] || suffixes[v] || suffixes[0]);
};
