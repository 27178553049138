import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {ProfessionAndSpecializationActions} from './profession-and-specialization.action';
import {ProfessionAndSpecializationActionTypes} from './profession-and-specialization.action-type';
import {selectRouterLocation} from '../../../lib/router/connected-router-saga';
import {RoutePaths} from '../../../lib/router/route-paths';
import {Toast} from '../../../lib/toast';
import {Debug} from '../../../utils/debug';
import {UserRoles} from '../../../utils/user-roles';
import {LoadingActions, LoadingTypes} from '../../loading';
import {LoggedInUserSelectors} from '../../user/modules/logged-in-user';
import {ProfessionAndSpecializationApi} from '../api/profession-and-specialization.api';

const getAllProfessionAndSpecializationValuesFlow = function* () {
    try {
        const data = yield call(ProfessionAndSpecializationApi.getAllValues);
        yield put(ProfessionAndSpecializationActions.storeAllValues(data));
    } catch (error) {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_PROFESSION_VALUE, false));

        Debug.error('professionAndSpecialization/ALL_VALUES_FLOW', 'Error: ', {error});
    }
};

const getProfessionAndSpecializationFlow = function* (freelancerId) {
    try {
        const data = yield call(ProfessionAndSpecializationApi.getFreelancerData, {freelancerId});
        yield put(ProfessionAndSpecializationActions.storeFreelancerData(data));
    } catch (error) {
        yield put(LoadingActions.setLoading(LoadingTypes.GET_PROFESSION_AND_SPECIALIZATION, false));
        Debug.error('professionAndSpecialization/SELECTED_VALUES_FLOW', 'Error: ', {error});
    }
};

const saveProfessionAndSpecializationFlow = function* ({freelancerId, data}) {
    try {
        const status = yield call(ProfessionAndSpecializationApi.saveFreelancerData, {freelancerId, data});
        if (200 === status) {
            yield put(ProfessionAndSpecializationActions.storeFreelancerData(data));
            Toast.success('profileUpdated');
        } else {
            Toast.error('genericError');
            yield put(LoadingActions.setLoading(LoadingTypes.SAVE_PROFESSION_AND_SPECIALIZATION, false));
        }
    } catch (error) {
        yield put(LoadingActions.setLoading(LoadingTypes.SAVE_PROFESSION_AND_SPECIALIZATION, false));
        Debug.error('professionAndSpecialization/SAVE_FREELANCER_DATA', 'Error: ', {error});
    }
};

export const getAllProfessionAndSpecializationValuesWorker = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    const location = yield select(selectRouterLocation);

    // Admins should not load ProfessionAndSpecializationValues data for themselves
    if (location && location.pathname.indexOf(RoutePaths.MY_PROFILE) !== -1
        && [UserRoles.ADMINISTRATOR, UserRoles.PAYROLL_ADMINISTRATOR].includes(loggedInUser?.role)) {
        return;
    }

    yield put(LoadingActions.setLoading(LoadingTypes.GET_PROFESSION_VALUE, true));

    yield call(getAllProfessionAndSpecializationValuesFlow);

    yield put(LoadingActions.setLoading(LoadingTypes.GET_PROFESSION_VALUE, false));
};

export const getProfessionAndSpecializationWorker = function* () {
    const loggedInUser = yield select(LoggedInUserSelectors.selectLoggedInUser);
    const location = yield select(selectRouterLocation);

    // Admins should not load ProfessionAndSpecializationValues data for themselves
    if (location && location.pathname.indexOf(RoutePaths.MY_PROFILE) !== -1
        && [UserRoles.ADMINISTRATOR, UserRoles.PAYROLL_ADMINISTRATOR].includes(loggedInUser?.role)) {
        return;
    }

    yield put(LoadingActions.setLoading(LoadingTypes.GET_PROFESSION_AND_SPECIALIZATION, true));
    const user = yield select(LoggedInUserSelectors.selectLoggedInUser);
    yield call(getProfessionAndSpecializationFlow, user.id);
    yield put(LoadingActions.setLoading(LoadingTypes.GET_PROFESSION_AND_SPECIALIZATION, false));
};

const saveProfessionAndSpecializationWorker = function* ({payload}) {
    yield put(LoadingActions.setLoading(LoadingTypes.SAVE_PROFESSION_AND_SPECIALIZATION, true));
    const user = yield select(LoggedInUserSelectors.selectLoggedInUser);
    yield call(saveProfessionAndSpecializationFlow, {freelancerId: user.id, data: payload});
    yield put(LoadingActions.setLoading(LoadingTypes.SAVE_PROFESSION_AND_SPECIALIZATION, false));
};


export const professionAndSpecializationSaga = function* () {
    yield all([
        takeEvery(
            ProfessionAndSpecializationActionTypes.GET_ALL_VALUES,
            getAllProfessionAndSpecializationValuesWorker,
        ),
        takeEvery(
            ProfessionAndSpecializationActionTypes.GET_FREELANCER_DATA,
            getProfessionAndSpecializationWorker,
        ),
        takeEvery(
            ProfessionAndSpecializationActionTypes.SAVE_FREELANCER_DATA,
            saveProfessionAndSpecializationWorker,
        ),
    ]);
};
