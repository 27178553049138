export const flexCenter = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
};

export const inset0 = {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
};

export const circle = size => ({
    borderRadius: '50%',
    minWidth: size,
    minHeight: size,
});
