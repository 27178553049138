import {useEffect} from 'react';
import {getConfig} from '../v1/config';

/**
 * Hook to dynamically load a script
 * @typedef {Object} ScriptOptions
 * @property {string} [src] - Script source URL
 * @property {string} [type='text/javascript'] - Script type
 * @property {string} [id] - Script ID
 * @property {string} [defer] - Whether to defer the script
 * @property {string} [async=true] - Whether to load the script asynchronously
 */

/**
 * @param {ScriptOptions} options - Script options
 * @param {string | undefined} [runInEnvironment] - Environment in which to run the script,
 *  if undefined, the script will run in all environments
 * @returns {void}
 */
const useScript = (options = {async: true, type: 'text/javascript'}, runInEnvironment) => {
    const {environment} = getConfig();

    useEffect(() => {
        // Skip script execution if we're not in the specified environment
        if (runInEnvironment && runInEnvironment !== environment) return;

        // Check if script already exists by id
        if (options.id && document.getElementById(options.id)) return;

        const script = document.createElement('script');

        Object.entries(options).forEach(([key, value]) => {
            script[key] = value;
        });

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [environment, options, runInEnvironment]);
};

export default useScript;
