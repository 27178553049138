import useScript from '../../hooks/use-script';

const HUBSPOT_ANALYTICS_URL = '//js-eu1.hs-scripts.com/139647359.js';

export const HubspotAnalytics = () => {
    useScript(
        {
            src: HUBSPOT_ANALYTICS_URL,
            async: true,
            type: 'text/javascript',
            defer: true,
            id: 'hs-script-loader',
        },
        'production',
    );

    return null;
};
